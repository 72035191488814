import React from 'react';
import PropTypes from 'prop-types';

const EmptyPage = ({ message = 'Welcome to OHIF' }) => {
  return (
    <div className="absolute flex h-full w-full items-center justify-center text-white">
      <div>
        <h4>{message}</h4>
      </div>
    </div>
  );
};

EmptyPage.propTypes = {
  message: PropTypes.string,
};

export default EmptyPage;
